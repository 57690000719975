import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  SetPassword
} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>

        {/* Web UI */}
        <Route exact path={Routes.login}>
          <SetPassword />
        </Route>

        {/* Mobile UI */}
        <Route exact path={Routes.home}>
          <SetPassword />
        </Route>

      </Switch>
    </Router>
  );
};

export default RouterApp;

import logo from './assets/logo.png';
import web_welcome_background from './assets/web_welcome_background.png';
import mob_welcome_background from './assets/mob_welcome_background.png';
import web_background from './assets/web_background.png';
import mob_background from './assets/mob_background.png';
import powered_by_logo from './assets/powered_by_logo.png';
import welcome_backdrop from './assets/welcome_backdrop.png';

export const initital_screen_state = {
  logo,
  web_welcome_background,
  mob_welcome_background,
  web_background,
  mob_background,
  powered_by_logo,
  welcome_backdrop,
};

export const getLeftSectionGrid = (size) => {
  let grid = size?.width > 1250
    ? 8 : size?.width > 1130
      ? 7.5 : size?.width > 1000
        ? 7 : size?.width > 830
          ? 6.5 : size?.width > 700
            ? 6 : size?.width > 650
              ? 5.5 : size?.width > 599
                ? 5 : 12;

  return grid;
}

export const getRightSectionGrid = (size) => {
  let grid = size?.width > 1250
    ? 4 : size?.width > 1130
      ? 4.5 : size?.width > 1000
        ? 5 : size?.width > 830
          ? 5.5 : size?.width > 700
            ? 6 : size?.width > 650
              ? 6.5 : size?.width > 599
                ? 7 : 12;

  return grid;
}

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./style";

export const Password = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography className={classes.Label} noWrap>
        {props.label}
        {props.isrequired ? (
          <Typography variant="caption" className={classes.required}>
            *
          </Typography>
        ) : (
          <Typography variant="caption" className={classes.required}></Typography>
        )}
      </Typography>
    );
  };
  const [showPassword, setShowPassword] = React.useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <TextField
          className={classes.textbox}
          id={props.id}
          placeholder={props.placeholder}
          variant={props.variant ?? "outlined"}
          fullWidth
          type={showPassword ? "text" : "password"}
          InputLabelProps={{
            shrink: false
          }}
          size="small"
          InputProps={{
            style: {
              fontSize: "0.875rem",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={props?.disabled ?? false}


          onChange={props.onChange}
          value={props.value}


        />
      </>
      {props.isError && <Typography variant={"caption"} color={"error"}>{props.errorMessage}</Typography>}

    </div>
  );
};

Password.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
Password.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
};

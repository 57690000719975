import makeStyles from '@mui/styles/makeStyles';
import { SemiBold } from '../../utils';

export const useStyles = makeStyles((theme) => ({
  unordered_list: {
    margin: "6px 0px 3px 18px",
    padding: "0px"
  },
  password_strength: {
    color: theme.typography.color.white,
    fontFamily: SemiBold,
    fontSize: theme.palette.labelFontSize,
  }
}));
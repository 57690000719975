import { useStyles } from "./style";

export const PasswordInfo = () => {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.password_strength} style={{ margin: "0px 0px 6px 2px" }}>
        For strong password
      </p>
      <ul className={classes.unordered_list}>
        <li className={classes.password_strength}>
          Password should contain atleast 1 capital letter.
        </li>
        <li className={classes.password_strength}>
          Password should contain atleast 1 special character.
        </li>
        <li className={classes.password_strength}>
          Password should contain atleast 1 digit.
        </li>
      </ul>
    </div>
  );
};

import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { Success } from "../../../components/success"
import { LightTooltip } from "../style"
import { PasswordInfo } from "../../../components"
import { InfoIcon } from "../assets/info_icon"
import { Password } from "../../../components/password"

export const WebUI = ({
  classes = {},
  screen_state = {},
  isSuccess = false,
  data = {},
  updateState = () => false,
  isInfoOpen = false,
  setIsInfoOpen = () => false,
  disable = false,
  submit = () => false,
}) => {
  return (
    <Container className={classes.web_right_section_card} maxWidth="sm">
      <Grid container className={classes.web_content}>
        <Grid item xs={12}>
          <Stack direction={"column"} alignItems={"start"} display={"grid"}>
            <Box height={"48px"} />
            <img className={classes.web_logo} src={screen_state?.logo} alt={"logo"} />
            <Box height={"16px"} />
            {isSuccess
              ? <>
                <Box height={"24px"} />
                <Grid item xs={12}>
                  <Stack height={"100%"} alignItems={"center"} >
                    <Success />
                  </Stack>
                </Grid>
              </>
              : <>
                <Typography className={classes.web_title}>
                  {"Reset your account password below"}
                </Typography>
                <Box height={"24px"} />
                <Password
                  value={data?.password ?? ""}
                  onChange={(e) => updateState("password", e.target.value)}
                  label={"Password"}
                  placeholder={"Enter Password"}
                  isError={data?.error?.password?.length > 0}
                  errorMessage={data?.error?.password}
                  isrequired />
                <Box height={"24px"} />
                <Password
                  value={data?.conform_password ?? ""}
                  onChange={(e) => updateState("conform_password", e.target.value)}
                  label={"Confirm Password"}
                  placeholder={"Enter Confirm Password"}
                  isError={data?.error?.conform_password?.length > 0}
                  errorMessage={data?.error?.conform_password}
                  isrequired />
                <Box height={"10px"} />
                <Box display={"flex"} justifyContent={"end"}>
                  <LightTooltip title={<PasswordInfo />} placement="left" open={isInfoOpen} arrow>
                    <Box
                      onClick={() => setIsInfoOpen(!isInfoOpen)}
                      onMouseEnter={() => setIsInfoOpen(true)}
                      onMouseLeave={() => setIsInfoOpen(false)}>
                      <InfoIcon />
                    </Box>
                  </LightTooltip>
                </Box>
              </>}
            <Box height={"24px"} />
          </Stack>
        </Grid>
        {isSuccess
          ? <></>
          : <Grid item xs={12} alignSelf={"self-end"}>
            <Stack direction={"column"} alignItems={"start"} display={"grid"}>
              <Button className={classes.web_login_button} disabled={disable}
                onClick={submit}>
                <Typography className={classes.web_login_button_text}>
                  {"Submit"}
                </Typography>
              </Button>
              <Box height={"24px"} />
              <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                justifyContent={"space-between"} alignItems={"center"}>
                <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={"powered_by"} />
              </Stack>
            </Stack>
          </Grid>}
      </Grid>
    </Container>
  )
}
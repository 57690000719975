import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  content: {
    padding: "12px 16px",
    boxShadow: "0px 2px 6px #00000014",
    backgroundColor: theme.palette.background.tertiary1,
  },
  title: {
    textAlign: "center",
    fontSize: remCalc(16),
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
    width: ({ size, is_back_button }) => is_back_button ? (size?.width - 92) : (size?.width - 32),
  },
  arrow: {
    fontSize: remCalc(20),
    color: theme.typography.color.primary,
  },
}));
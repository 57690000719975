import { Hidden } from "@mui/material";
import { useStyles } from "./style";
import { useLocation } from "react-router-dom";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { useState } from "react";
import { useContext } from "react";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { MobileUI } from "./components/mobileUI";
import { WebUI } from "./components/webUI";
import { Routes } from "../../router/routes";

const initial = {
  password: "",
  conform_password: "",
  error: {
    password: "",
    conform_password: "",
  }
}

export const SetPasswordPage = ({
  size = {},
  screen_state = {},
}) => {
  const classes = useStyles({ size });
  const [data, setData] = useState({ ...initial })
  const search = useLocation().search;
  const alert = useContext(AlertContext);
  const token = new URLSearchParams(search).get("token");
  const [disable, setDisable] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    setData({ ...data, [key]: value })
  }

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = data?.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(data.password)

    //Checking enterPassword
    if (data?.password?.length === 0) {
      isValid = false;
      error.password = "Password is Required";
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.password = "Password is not stong";
    }
    if (data?.conform_password?.length === 0) {
      isValid = false;
      error.conform_password = "Confirm Password is Required";
    }
    if (data?.error?.password === "" && data?.password !== "" && data?.conform_password !== "") {
      if (data?.password !== data?.conform_password) {
        isValid = false
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Password Does not match",
        });
      }
    }
    setData({ ...data, error });
    return isValid;
  };

  const submit = () => {
    setDisable(true);
    if (isIamValideToLogin() && token) {
      const payload = {
        password: data?.password,
        token: token
      };
      NetworkCall(
        `${config.api_url}/auth/resetpassword`,
        NetWorkCallMethods.post, payload, null, false, false
      ).then((response) => {
        setIsSuccess(true);
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.success,
          msg: "Password Updated Successfully",
        });
      }).catch((err) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: "User Not Found",
        });
      });
    } else {
      setDisable(false);
      return false;
    }
  };

  return (
    window?.location?.pathname === Routes?.login
      ? <>
        {/* Mobile UI */}
        <Hidden smUp>
          <MobileUI
            classes={classes}
            size={size}
            isSuccess={isSuccess}
            screen_state={screen_state}
            data={data}
            updateState={updateState}
            isInfoOpen={isInfoOpen}
            setIsInfoOpen={setIsInfoOpen}
            disable={disable}
            submit={submit} />
        </Hidden>
        {/* Web & Tablet UI */}
        <Hidden smDown>
          <WebUI
            classes={classes}
            screen_state={screen_state}
            isSuccess={isSuccess}
            data={data}
            updateState={updateState}
            isInfoOpen={isInfoOpen}
            setIsInfoOpen={setIsInfoOpen}
            disable={disable}
            submit={submit} />
        </Hidden>
      </>
      : <MobileUI
        classes={classes}
        size={size}
        isSuccess={isSuccess}
        screen_state={screen_state}
        data={data}
        updateState={updateState}
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
        disable={disable}
        submit={submit} />
  );
}
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { TitleBar } from "../../../components/titlebar"
import { Success } from "../../../components/success"
import { Password } from "../../../components/password"
import { LightTooltip } from "../style"
import { PasswordInfo } from "../../../components"
import { InfoIcon } from "../assets/info_icon"

export const MobileUI = ({
  classes = {},
  size = {},
  isSuccess = false,
  screen_state = {},
  data = {},
  updateState = () => false,
  isInfoOpen = false,
  setIsInfoOpen = () => false,
  disable = false,
  submit = () => false,
}) => {
  return (
    <Container className={classes.mob_right_section_card} maxWidth="sm">
      <TitleBar title={"Reset Your Password"} />
      <Grid container className={classes.mob_content}>
        <Grid item xs={12} className={classes.mob_body_items}
          sx={{ height: size?.height - (isSuccess ? 54 : 160), overflow: "overlay" }}>
          <Box className={classes.mob_background}>
            <img className={classes.mob_background_image}
              src={screen_state?.mob_background} alt="" />
          </Box>
          <Box height={"16px"} />
          {isSuccess
            ? <>
              <Grid item xs={12}>
                <Stack height={"100%"} alignItems={"center"} >
                  <Success />
                </Stack>
              </Grid>
              <Box height={"16px"} />
            </>
            : <>
              <Typography className={classes.web_title}>
                {"Reset your account password below"}
              </Typography>
              <Box height={"16px"} />
              <Password
                value={data?.password ?? ""}
                onChange={(e) => updateState("password", e.target.value)}
                label={"Password"}
                placeholder={"Enter Password"}
                isError={data?.error?.password?.length > 0}
                errorMessage={data?.error?.password}
                isrequired />
              <Box height={"24px"} />
              <Password
                value={data?.conform_password ?? ""}
                onChange={(e) => updateState("conform_password", e.target.value)}
                label={"Confirm Password"}
                placeholder={"Enter Confirm Password"}
                isError={data?.error?.conform_password?.length > 0}
                errorMessage={data?.error?.conform_password}
                isrequired />
              <Box height={"10px"} />
              <Box display={"flex"} justifyContent={"end"}>
                <LightTooltip title={<PasswordInfo />} placement="left" open={isInfoOpen} arrow>
                  <Box
                    onClick={() => setIsInfoOpen(!isInfoOpen)}
                    onMouseEnter={() => setIsInfoOpen(true)}
                    onMouseLeave={() => setIsInfoOpen(false)}>
                    <InfoIcon />
                  </Box>
                </LightTooltip>
              </Box>
            </>}
        </Grid>
        {isSuccess
          ? <></>
          : <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
            <Stack direction={"column"} alignItems={"start"}>
              <Button className={classes.mob_set_up_new_button} disabled={disable}
                onClick={submit}>
                <Typography className={classes.mob_set_up_new_button_text}>
                  {"Submit"}
                </Typography>
              </Button>
              <Box height={"16px"} />
              <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                justifyContent={"space-between"} alignItems={"center"}>
                <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={"powered_by"} />
              </Stack>
            </Stack>
          </Grid>}
      </Grid>
    </Container>
  )
}
import { Box, Container, Grid, Hidden } from "@mui/material";
import { useWindowDimensions } from "../../utils";
import { SetPasswordPage } from "./setpasswordpage.js";
import { useStyles } from "./style";
import { getLeftSectionGrid, getRightSectionGrid, initital_screen_state } from "../../utils/login/utils";
import { Routes } from "../../router/routes.js";

const SetPassword = () => {
  const screen_state = initital_screen_state;
  const size = useWindowDimensions()
  const classes = useStyles({ size });

  return (
    window?.location?.pathname === Routes?.login
      ? <>
        <Hidden smUp>
          <SetPasswordPage size={size}
            screen_state={screen_state}
            initital_screen_state={initital_screen_state} />
        </Hidden>
        <Hidden smDown>
          <Box className={classes.root}>
            <Grid container className={classes.grid}>
              <Grid item xs={getLeftSectionGrid(size)} className={classes.left_section}>
                {size?.width > 599 &&
                  <img className={classes.web_background}
                    src={screen_state?.web_background} alt="" />}
              </Grid>
              <Grid item
                xs={getRightSectionGrid(size)}
                className={classes.right_section}>
                <SetPasswordPage size={size}
                  screen_state={screen_state}
                  initital_screen_state={initital_screen_state} />
              </Grid>
            </Grid>
          </Box>
        </Hidden>
      </>
      : <Container maxWidth="sm" style={{ padding: 0, position: "relative", height: "100vh" }}>
        <SetPasswordPage size={size}
          screen_state={screen_state}
          initital_screen_state={initital_screen_state} />
      </Container>
  );
};
export default SetPassword
